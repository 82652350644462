
export function getImageByRelativePath(src, data){
    const keys= Object.keys(data);
    let plaatje= data[keys[0]].childImageSharp.gatsbyImageData;
    keys.forEach((key, index) => {
        if (data[key].relativePath === src){
            plaatje  = data[key].childImageSharp.gatsbyImageData;
            return plaatje;
        }
        else{
            return data[keys[0]].childImageSharp.gatsbyImageData;
        }
    });

    return plaatje;
}

export const isBrowser = () => typeof window !== 'undefined';