import React from 'react'
import './variables.css'
import './global.css'
import Seo from './seo'
import { default as Header } from './HeaderView';

import Footer from './footer'
class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        <Seo title={this.props.title} />
        <Header siteTitle={'Time Stables'}></Header>
        <main>{children}</main>
        <Footer />
      </>
    )
  }
}

export default Template
