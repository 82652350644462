import React, { useState, ReactNode, } from 'react';

import './Collapse.css';

type Props = {
    children: ReactNode[] | ReactNode,
    collapsed?: boolean,
    showLabel?: any,
    hideLabel?: any,
}

const Collapse = ({ children, collapsed = true, showLabel = 'Show', hideLabel = 'Hide' }: Props) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    return (
        <>
            <div
                className='collapse-button'
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                {isCollapsed ? showLabel : hideLabel}
            </div>
            <div
                className={`collapse-content ${isCollapsed ? '' : 'expanded'}`}
            >
                <div className={`collapse-text ${isCollapsed ? '' : 'expanded'}`}>{children}</div>
            </div>
        </>
    );
};

export default Collapse;