import React, { FC, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import useWindowDimensions from '../hooks/useWindowDimensions';
import Collapse from '../components/Collapse';
import './Header.css'


type HeaderProps = {
    siteTitle: string
};

export const ROUTES = [
    { to: '/', title: 'home' },
    { to: '/tarieven', title: 'tarieven' },
    { to: '/manege', title: 'manege' },
    { to: '/pension', title: 'pension' },
    { to: '/verkooppaarden', title: 'verkooppaarden' },
    { to: '/evenementen', title: 'evenementen' },
    { to: '/restuarant', title: 'restaurant' },
    { to: '/openingstijden', title: 'openingstijden' },
    { to: '/contact', title: 'contact' },
    { to: '/login', title: 'login' },
];

const HeaderView: FC<HeaderProps> = () => {
    const { width } = useWindowDimensions();
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        width > 1000 ? <header
            style={{
                margin: `0 auto`,
                backgroundColor: 'var(--background)',
                padding: `24px var(--size-gutter)`,
                display: `flex`,
                alignItems: `center`,
                justifyContent: `space-between`,
                width: '100%',
                position: 'fixed',
                top: '0',
                zIndex: '100000'

            }}
        >
                        <StaticImage
                src='../../static/ts_logo_wit.png'
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
                style={{ width: '50px' }}
                backgroundColor='#1d1d22'
                placeholder='none'
            />
            <Link
                key='home'
                to='/'
                style={{
                    fontSize: `var(--font-m)`,
                    opacity: .5,
                    textDecoration: 'none',
                    color: '#fff'
                }}
                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
            
            >
                Home
            </Link>

            <Link
                key='tarieven'
                to='/tarieven'
                style={{
                    fontSize: `var(--font-m)`,
                    opacity: .5,
                    textDecoration: 'none',
                    color: '#fff'
                }}
                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
            >
                Tarieven
            </Link>

            <Link
                key='manege'
                to='/manege'
                style={{
                    fontSize: `var(--font-m)`,
                    opacity: .5,
                    textDecoration: 'none',
                    color: '#fff'
                }}
                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
            >
                Manege
            </Link>

            <Link
                key='pension'
                to='/pension'
                style={{
                    fontSize: `var(--font-m)`,
                    opacity: .5,
                    textDecoration: 'none',
                    color: '#fff'
                }}
                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
            >
                Pension
            </Link>



            <Link
                key='verkooppaarden'
                to='/verkooppaarden'
                style={{
                    fontSize: `var(--font-m)`,
                    opacity: .5,
                    textDecoration: 'none',
                    color: '#fff'
                }}
                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
            >
                Verkooppaarden
            </Link>



            <Link
                key='evenementen'
                to='/evenementen'
                style={{
                    fontSize: `var(--font-m)`,
                    opacity: .5,
                    textDecoration: 'none',
                    color: '#fff'
                }}
                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
            >
                Evenementen
            </Link>

            <Link
                key='restaurant'
                to='/restaurant'
                style={{
                    fontSize: `var(--font-m)`,
                    opacity: .5,
                    textDecoration: 'none',
                    color: '#fff'
                }}
                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
            >
                Restaurant
            </Link>
            <Link
                key='openingstijden'
                to='/openingstijden'
                style={{
                    fontSize: `var(--font-m)`,
                    opacity: .5,
                    textDecoration: 'none',
                    color: '#fff'
                }}
                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
            >
                Openingstijden
            </Link>

            <Link
                key='contact'
                to='/contact'
                style={{
                    fontSize: `var(--font-m)`,
                    opacity: .5,
                    textDecoration: 'none',
                    color: '#fff'
                }}
                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
            >
                Contact
            </Link>
            <div>
              <button onClick={() => navigate('/login')} className='button' >Inloggen</button>
              <button onClick={() => navigate('/register')} className='button_transparent' >Registreren</button>
            </div>


        </header> :
            <header
                style={{
                    width: '100%',
                    backgroundColor: 'var(--background)',
                }}
            >
                <div>
                    <Collapse
                        collapsed={!expanded}
                        showLabel={
                            <div className='nav-content' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%', padding: 20, whiteSpace: 'nowrap' }}>
                                <StaticImage
                                    src='../../static/ts_logo_wit.png'
                                    quality={95}
                                    formats={['auto', 'webp', 'avif']}
                                    loading='eager'
                                    alt='Intro banner'
                                    style={{ width: '40px' }}
                                    backgroundColor='#1d1d22'
                                    placeholder='none'
                                />
                                <div onClick={() => setExpanded(!expanded)}>                                
                                <StaticImage
                                    src='../icons/hamburger_o.png'
                                    quality={95}
                                    formats={['auto', 'webp', 'avif']}
                                    loading='eager'
                                    alt='Intro banner'
                                    style={{ width: '30px' }}
                                />
                                </div>
                            </div>
                        }
                        hideLabel={
                            <div className='nav-content' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%', padding: 20, whiteSpace: 'nowrap' }}>
                                <StaticImage
                                    src='../../static/ts_logo_wit.png'
                                    quality={95}
                                    formats={['auto', 'webp', 'avif']}
                                    loading='eager'
                                    alt='Intro banner'
                                    style={{ width: '180px' }}
                                />
                                <div onClick={() => setExpanded(!expanded)}>
                                    <StaticImage
                                        src='../icons/close_o.png'
                                        quality={95}
                                        formats={['auto', 'webp', 'avif']}
                                        loading='eager'
                                        alt='Intro banner'
                                        style={{ width: '30px' }}
                                    />
                                </div>
                            </div>
                        }
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', padding: '0 20px' }}>
                            <br />
                            
                            <Link
                                key='home'
                                to='/'
                                style={{
                                    fontSize: `var(--font-m)`,
                                    opacity: .5,
                                    padding: '5px 0',
                                    textDecoration: 'none'

                                }}
                                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
                            >
                                Home
                            </Link>
                            <Link
                                key='tarieven'
                                to='/tarieven'
                                style={{
                                    fontSize: `var(--font-m)`,
                                    opacity: .5,
                                    padding: '5px 0',
                                    textDecoration: 'none'

                                }}
                                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
                            >
                                Tarieven
                            </Link>

                            <Link
                                key='manege'
                                to='/manege'
                                style={{
                                    fontSize: `var(--font-m)`,
                                    opacity: .5,
                                    padding: '5px 0',
                                    textDecoration: 'none'
                                }}
                                activeStyle={{ opacity: 1, textDecorationColor: `var(--primary)` , textDecoration: 'underline'}}
                            >
                                Manege
                            </Link>

                            <Link
                                key='pension'
                                to='/pension'
                                style={{
                                    fontSize: `var(--font-m)`,
                                    opacity: .5,
                                    padding: '5px 0',
                                    textDecoration: 'none'
                                }}
                                activeStyle={{ opacity: 1, textDecorationColor: `var(--color-primary)` , textDecoration: 'underline'}}
                            >
                                Pension
                            </Link>

                            <Link
                                key='verkooppaarden'
                                to='/verkooppaarden'
                                style={{
                                    fontSize: `var(--font-m)`,
                                    opacity: .5,
                                    padding: '5px 0',
                                    textDecoration: 'none'
                                }}
                                activeStyle={{ opacity: 1, textDecorationColor: `var(--color-primary)` , textDecoration: 'underline'}}
                            >
                                Verkooppaarden
                            </Link>

                            <Link
                                key='evenementen'
                                to='/evenementen'
                                style={{
                                    fontSize: `var(--font-m)`,
                                    opacity: .5,
                                    padding: '5px 0',
                                    textDecoration: 'none'
                                }}
                                activeStyle={{ opacity: 1, textDecorationColor: `var(--color-primary)` , textDecoration: 'underline'}}
                            >
                                Evenementen
                            </Link>

                            <Link
                                key='restaurant'
                                to='/restaurant'
                                style={{
                                    fontSize: `var(--font-m)`,
                                    opacity: .5,
                                    padding: '5px 0',
                                    textDecoration: 'none'
                                }}
                                activeStyle={{ opacity: 1, textDecorationColor: `var(--color-primary)` , textDecoration: 'underline'}}
                            >
                                Restaurant
                            </Link>

                            <Link
                                key='openingstijden'
                                to='/openingstijden'
                                style={{
                                    fontSize: `var(--font-m)`,
                                    opacity: .5,
                                    padding: '5px 0',
                                    textDecoration: 'none'
                                }}
                                activeStyle={{ opacity: 1, textDecorationColor: `var(--color-primary)` , textDecoration: 'underline'}}
                            >
                                Openingstijden
                            </Link>

                            <Link
                                key='contact'
                                to='/contact'
                                style={{
                                    fontSize: `var(--font-m)`,
                                    opacity: .5,
                                    padding: '5px 0',
                                    textDecoration: 'none'
                                }}
                                activeStyle={{ opacity: 1, textDecorationColor: `var(--color-primary)` , textDecoration: 'underline'}}
                            >
                                Contact
                            </Link>

                            <br />
                        </div>
                    </Collapse>
                </div>
            </header>
    );
}

export default HeaderView;