import { useState, useEffect } from 'react';
import { isBrowser } from '../shared/utilities';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = isBrowser() ? window : { innerWidth: 0, innerHeight: 0 };
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState<{ width: number, height: number }>(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}