import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import Container from './container'
import * as styles from './footer.module.css'

const Footer = () => (
  <Container as="footer">
    <div className={styles.container}>

    <div
          style={{
              width: '100%',
              display: `flex`,
              alignItems: `center`,
              justifyContent: `space-evenly`,
              marginBottom: '100px'
          }}
      >
        <StaticImage style={{maxWidth: '100px'}} src='../../static/FNRS-logo.png' alt=''/>
        <StaticImage style={{maxWidth: '100px'}} src='../../static/knhs.svg' alt=''/>
        <StaticImage style={{maxWidth: '100px'}} src='../../static/veligpaardlogo.png' alt=''/>


      </div>

      <div
          style={{
              width: '100%',
              display: `flex`,
              alignItems: `center`,
              justifyContent: `space-around`,
          }}
      >
          <a href='Algemene_Voorwaarden_FNRS_.pdf'>Algemene voorwaarden</a>
          <a href='privacy_ts.pdf'>Privacy statement</a>


      </div>
    </div>
  </Container>
)

export default Footer
